@import '~assets/sass/variables';

.radio-option {
    margin: 0 4px;
}

.radio-option-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
    height: 110px;
    padding-top: 16px;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0.6;
    filter: grayscale(1);

    &:hover {
        background-color: rgba($gray, 0.15);
    }
}

.radio-option-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + .radio-option-label {
        background-color: rgba($gray, 0.15);
        opacity: 1;
        filter: grayscale(0);
    }

    &:disabled + .radio-option-label {
        background-color: #fff;
        opacity: 0.3;
        cursor: default;
    }
}

.radio-option-icon {
    width: 60px;
    pointer-events: none;
}

.radio-option-name {
    margin-top: 9px;
    font-family: 'ArialBold', serif;
    color: $black;
    font-size: 11px;
}
