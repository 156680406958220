// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming

.mat-dialog-container {
    padding: 0;
}

// Plus imports for other components in your app.

$avail-blue: (
    50: #e7ecf7,
    100: #c2cfed,
    200: #9ab0e0,
    300: #6f91d4,
    400: #4a78cb,
    500: #1060c3,
    600: #0058b9,
    700: #004dad,
    800: #0043a1,
    900: #00328c,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: white,
    ),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avail-primary: mat.define-palette($avail-blue);
$avail-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$avail-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$avail-theme: mat.define-light-theme(
    $avail-primary,
    $avail-accent,
    $avail-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($avail-theme);
