@import 'variables';

.title-search-registry-select {
    &.mat-select {
        display: flex;
        align-items: center;
        width: 262px;
        height: 32px;
        padding: 0 16px;
        border-radius: 2px;
        background-color: #f4f4f5;
    }

    & .mat-select-arrow {
        color: #96969e !important;
    }
}

.mat-select-panel {
    &.expanded {
        max-height: 290px;
    }

    &.search-registry-select-panel {
        min-width: calc(100% + 24px) !important;
        transform: translateX(4px) !important;
    }

    & .mat-option {
        background-color: transparent;

        &.mat-selected:not(.mat-option-multiple) {
            background-color: transparent;
            color: $black;
        }
    }
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background-color: transparent;
}
