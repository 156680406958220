@font-face {
    font-family: 'ArialRegular';
    src: url('~assets/fonts/ArialRegular/ArialRegular.eot');
    src:
        url('~assets/fonts/ArialRegular/ArialRegular.eot?#iefix')
            format('embedded-opentype'),
        url('~assets/fonts/ArialRegular/ArialRegular.woff') format('woff'),
        url('~assets/fonts/ArialRegular/ArialRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ArialBold';
    src: url('~assets/fonts/ArialBold/ArialBold.eot');
    src:
        url('~assets/fonts/ArialBold/ArialBold.eot?#iefix')
            format('embedded-opentype'),
        url('~assets/fonts/ArialBold/ArialBold.woff') format('woff'),
        url('~assets/fonts/ArialBold/ArialBold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}
