@import '~assets/sass/variables';

// Angular Material dialog castomization
.cdk-overlay-dark-backdrop {
    background-color: rgba($black, 0.85);
}

.notice-folder-details-dialog {
    & .avl-btn {
        background-color: $notice-yellow;

        &:hover {
            background-color: rgba($notice-yellow, 0.7);
        }
    }

    & .form-control {
        &:focus {
            box-shadow: inset 0 0 0 1px rgba($notice-yellow, 0.4);
            border-color: rgba($notice-yellow, 0.5);
        }

        &:hover {
            border-color: rgba($notice-yellow, 0.7);
        }
    }
}

.mat-dialog-title {
    padding: 16px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    color: $dark-gray;
    border-bottom: 1px solid #eaeaea;
}

.mat-dialog-content {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mat-dialog-actions {
    margin-bottom: 0 !important;
}

.report-dialog,
.confirm-dialog,
.options-dialog,
.error-alert-dialog,
.full-screen-dialog,
.notification-center,
.schedule-preview-dialog {
    .mat-dialog-container {
        position: relative;
        padding: 0;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgba(2, 3, 3, 0.25);
    }
}

.snackbar-view {
    &.mat-snack-bar-container {
        max-width: 100%;
        padding: 0;
        margin: 50px;
        background: transparent;
        box-shadow: none;
    }
}

.schedule-preview-dialog {
    h4 {
        margin: 0;
    }
}

.report-dialog,
.error-alert-dialog,
.notification-center {
    .dialog {
        &__close {
            top: 12px;
            right: 15px;
        }
    }
}

.notification-center {
    .mat-dialog-container {
        margin: 0 128px;
        background: transparent;
        box-shadow: none;
        overflow: hidden;
    }
}

.dialog {
    &__close,
    &__left-top-btn {
        position: absolute;
        top: 14px;
        right: 18px;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        padding: 0;
        transition: all 0.2s ease;
        cursor: pointer;

        .close-icon {
            opacity: 0.6;
            transition: opacity 0.2s ease;
        }

        &:hover {
            background-color: rgba($gray, 0.3);

            .close-icon {
                opacity: 1;
            }
        }

        &:active {
            background-color: rgba($gray, 0.5);
        }
    }

    &__bottom-btn {
        padding-top: 25px;
        padding-bottom: 25px;
        border-radius: 0;
    }

    &__left-top-btn {
        left: 18px;
    }
}

.full-screen-dialog {
    width: 100%;
}

.filed-copies-dialog {
    width: 68%;
    height: 600px;

    .mat-dialog-title {
        text-align: center;
        margin-bottom: 32px;
    }

    .mat-dialog-container {
        position: relative;
        padding: 0;
    }

    .mat-dialog-content {
        flex-grow: 1;
        padding: 10px 0 0 0;
    }
}

.dialog-checkbox {
    margin-left: 24px;

    .mat-checkbox-layout {
        .mat-checkbox-label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #333333;
        }
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background: #6f90d4;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #6f90d4;
}

@media (max-width: 992px) {
    .notification-center {
        .mat-dialog-container {
            margin: 0 64px;
        }
    }
}

@media (max-width: 768px) {
    .notification-center {
        .mat-dialog-container {
            margin: 0 32px;
        }
    }
}
