.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
}

.mat-expansion-panel-header {
    position: relative;
    font-family: 'ArialRegular';
    font-size: 12px;

    .mat-content {
        align-items: center;
    }

    .mat-expansion-indicator {
        position: absolute;
        display: flex;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;

        &:after {
            content: none;
            border-style: none;
        }

        &:before {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            background-image: url('~assets/icons/icon_dropdown.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}

.mat-expansion-panel-header-description {
    position: relative;
    color: #fff;
}

.mat-expansion-panel-content {
    font-family: 'ArialRegular';

    .mat-expansion-panel-body {
        padding: 0;

        .mat-expansion-panel-header-description {
            margin: 0;
        }
    }
}

.mat-expansion-panel-header-description {
    align-items: center;
    padding: 10px 24px;
    background-color: mix($red, rgba(0, 0, 0, 0.2));
}
