@import 'variables';

// Angular Material sort castomization
.mat-sort-header-container {
    .mat-sort-header-button {
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.mat-paginator-range-label {
    font-family: 'ArialRegular';
    color: $black;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.mat-paginator {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .mat-button-ripple.mat-ripple {
        border-radius: 3px;
    }

    &.hidden {
        display: none;
    }
}

.cdk-drag-preview {
    opacity: 1 !important;
    background-color: white;
    font-size: 13px;
    color: #000113;
}

.drag-drop {
    &__preview {
        display: flex;
        align-items: center;
    }

    &__placeholder {
        background-color: $gray;
        transition: transform 250ms linear;
    }
}
