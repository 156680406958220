// Colors
$light-blue: #8bacf0;
$blue: #6f90d4;
$mid-blue: #4b68a4;
$dark-blue: #0f4d97;
$green: #8ac291;
$red: #da757f;
$bright-red: #be2535;
$pink: #f6616e;
$dark: #49536b;
$light-gray: #d6d7dd;
$gray: #ccc;
$mid-gray: #f2f2f2;
$dark-gray: #999;
$black: #333;
$dark-green: #5ac39f;
$silver: #bbb;
$purple: #724496;
$dark-purple: #3e1e7d;
$yellow: #f0d687;
$dark-yellow: #f0c234;
$notice-yellow: #ec9c42;

$folder-width: 1440px;
$folder-xl-padding: 128px;
$folder-lg-padding: 64px;
$folder-md-padding: 32px;
