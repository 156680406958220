@import '~assets/sass/variables';

// The styles for the assets/icons/icon_notice_loading.svg
.loading-svg {
    $loading-color: $notice-yellow;
    $complete-color: #5ac39f;

    position: relative;

    &__circle {
        stroke: #bbbbbb;
        animation: movePart 0.5s linear;
        transform-origin: center;
        transform: rotate(-90deg);

        .file-over & {
            stroke: rgba($loading-color, 0.3);
        }

        .uploading & {
            stroke: rgba($loading-color, 0.3);
        }
    }

    &__animated-circle {
        stroke: $complete-color;
        transform-origin: center;
        opacity: 0;

        .completed & {
            opacity: 1;
            stroke-dasharray: 144;
            stroke-dashoffset: 0;
            animation: movePart ease 0.5s forwards;
            transform: rotate(270deg);
        }
    }

    &__part {
        stroke: $loading-color;
        transform-origin: center;
        stroke-dasharray: 40;
        stroke-dashoffset: 10;
        opacity: 0;
        transform: rotate(-90deg);

        .uploading & {
            opacity: 1;
            animation: dash 2s linear infinite;
        }

        .completed & {
            display: none;
            opacity: 0;
        }
    }

    &__down {
        fill: #bbb;

        .file-over & {
            fill: $loading-color;
        }

        .uploading & {
            fill: $loading-color;
        }

        .completed & {
            display: none;
        }
    }

    &__arrow {
        fill: #bbb;

        .file-over & {
            fill: $loading-color;
            animation: moveArrow 1s ease infinite;
        }

        .uploading & {
            fill: $loading-color;
            animation: moveArrow 1s ease infinite;
        }

        .completed & {
            display: none;
        }
    }

    &__tick {
        stroke: $complete-color;
        stroke-dasharray: 30;
        stroke-dashoffset: 30;
        transform: translateY(2px);

        .completed & {
            animation: moveTick 0.5s ease forwards;
        }
    }
}

// animation
@keyframes dash {
    0% {
        stroke-dashoffset: 10;
        transform: rotate(0deg);
    }

    50% {
        stroke-dashoffset: 0;
        transform: rotate(260deg);
    }

    80% {
        stroke-dashoffset: 40;
        transform: rotate(260deg);
    }

    90% {
        stroke-dashoffset: 40;
        transform: rotate(356deg);
    }

    100% {
        stroke-dashoffset: 90;
        transform: rotate(360deg);
    }
}

@keyframes moveArrow {
    50% {
        transform: translate(0, 10%);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes movePart {
    0% {
        stroke-dashoffset: 144;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes moveTick {
    0% {
        stroke-dashoffset: 30;
    }

    100% {
        stroke-dashoffset: 0;
    }
}
