@import './variables';

.collection {
    &__container {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #fff;
        border-radius: 5px;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
    }

    &__title {
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: $dark-gray;
    }

    &__table-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .mat-paginator-container {
            padding: 0 24px;
        }
    }

    &__table-wrap {
        overflow-x: auto;
        flex: 1;
    }

    &__table {
        width: 100%;
        font-family: 'ArialRegular';

        tr.mat-header-row {
            height: 44px;
            text-transform: uppercase;
        }

        .mat-header-cell {
            color: $dark-gray;
            font-size: 10px;
            letter-spacing: 1px;
        }

        th.mat-header-cell,
        td.mat-cell {
            padding-right: 16px;
            padding-left: 16px;
            border-bottom-color: rgba($gray, 0.3);

            &.sortable {
                background: rgba(0, 0, 0, 0.04);
            }
        }

        td.mat-cell {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .checkbox-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__add-all-control {
        display: flex;
        align-items: center;
        cursor: pointer;

        .checkbox-input:checked ~ {
            .mat-icon {
                .add-all-box,
                .add-all-check {
                    fill: $purple;
                }

                .add-all-check {
                    opacity: 1;
                }
            }

            .checkbox-text {
                color: $purple;
            }
        }

        .mat-icon {
            width: 20px;
            height: 20px;

            .add-all-box,
            .add-all-check {
                fill: $dark-gray;
                transition: all 0.2s linear;
            }

            .add-all-check {
                opacity: 0;
            }
        }

        .checkbox-text {
            margin-left: 8px;
            color: $dark-gray;
            font-size: 14px;
            letter-spacing: 0.1px;
        }
    }

    &__add-control {
        .checkbox-input:checked ~ {
            .collection__add-label {
                background-color: $dark-green;

                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__add-label {
        position: relative;
        max-width: 88px;
        height: 32px;
        background-color: $blue;
        border-radius: 3px;
        color: #fff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            background-color: $mid-blue;
        }

        &:before {
            content: 'Add';
            text-transform: uppercase;
            opacity: 1;
            transition: opacity 0.2s linear;
        }

        &:after {
            content: '';
            width: 18px;
            height: 15px;
            display: block;
            position: absolute;
            top: 8px;
            left: 50%;
            background-image: url('~assets/icons/icon_small_white-check.svg');
            background-repeat: no-repeat;
            opacity: 0;
            transform: translateX(-50%);
            transition: opacity 0.2s linear;
        }
    }
}
