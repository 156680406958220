@import 'variables';

button {
    padding: 0;
    margin: 0;
}

.avl-btn {
    position: relative;
    display: inline-block;
    padding: 15px 25px;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'ArialBold';
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    box-shadow: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &--blue {
        background-color: $blue;

        &:hover {
            background-color: $light-blue;
        }

        &:active {
            background-color: $mid-blue;
        }

        &-border {
            border: 2px solid $blue;
            color: $blue;

            &:hover {
                background-color: $blue;
                color: #fff;
            }

            &:active {
                background-color: $mid-blue;
            }
        }
    }

    &--dark {
        background-color: $dark;

        &:hover {
            background-color: rgba($dark, 0.7);
        }

        &:active {
            background-color: rgba($dark, 0.7);
        }

        &.avl-btn--outline {
            color: $dark;
            border-color: $dark;

            &:active,
            &:hover {
                color: rgba($dark, 0.7);
                border-color: rgba($dark, 0.7);
            }
        }
    }

    &--red {
        background-color: $red;

        &:hover {
            background-color: #f18f98;
        }

        &:active {
            background-color: #a84b54;
        }
    }

    &--bright-red {
        background-color: $bright-red;

        &:hover {
            background-color: rgba($bright-red, 0.7);
        }

        &:active {
            background-color: rgba($bright-red, 0.8);
        }
    }

    &--green {
        background-color: $green;

        &:hover {
            background-color: rgba($green, 0.7);
        }
    }

    &--dark-green {
        background-color: $dark-green;

        &:hover {
            background-color: rgba($dark-green, 0.7);
        }

        &:active {
            background-color: $dark-green;
        }
    }

    &--purple {
        background-color: $purple;

        &:hover {
            background-color: rgba($purple, 0.7);
        }
    }

    &--dark-purple {
        background-color: $dark-purple;

        &:hover {
            background-color: rgba($dark-purple, 0.7);
        }
    }

    &--dark-blue {
        background-color: $dark-blue;

        &:hover {
            background-color: rgba($dark-blue, 0.7);
        }
    }

    &--yellow {
        background-color: $dark-yellow;

        &:hover {
            background-color: $yellow;
        }

        &:active {
            background-color: $dark-yellow;
        }
    }

    &--notice-yellow {
        background-color: $notice-yellow;

        &:hover {
            background-color: rgb($notice-yellow, 0.7);
        }

        &:active {
            background-color: $notice-yellow;
        }
    }

    &--gray {
        background-color: $gray;

        &:hover {
            background-color: rgba($gray, 0.7);
        }
    }

    &--mid-gray {
        background-color: $mid-gray;
        color: $gray;

        &:hover {
            background-color: rgba($mid-gray, 0.7);
        }
    }

    &--wide {
        width: 100%;
    }

    &--large {
        padding-top: 22px;
        padding-bottom: 22px;
        width: 100%;
    }

    .avl-btn-spinner {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        margin-left: -22px;
        vertical-align: middle;
        text-indent: -9999px;
        animation: 0.35s linear infinite btn-spin;
        transition:
            0.4s margin ease-out,
            0.2s opacity ease-out;
        border: 2px solid #fff;
        border-right-color: transparent;
        border-radius: 100%;
        overflow: hidden;
        opacity: 0;
    }

    &.is-loading {
        .avl-btn-spinner {
            margin-left: 10px;
            transition:
                0.2s margin ease-in,
                0.4s opacity ease-in;
            opacity: 1;
        }
    }

    &--outline {
        background: none;
        border: 2px solid;
        box-sizing: border-box;

        &:hover,
        &:active {
            background: none;
        }
    }
}

.pulsed-button {
    box-shadow: 0 0 0 rgba($dark, 0.4);
    animation: 1.8s linear infinite btn-pulse;
    animation-delay: 0.4s;
}

.clear-btn-styles {
    all: unset;
    cursor: pointer;
}

@keyframes btn-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($dark, 0.6);
        background-color: $dark;
    }

    20% {
        box-shadow: 0 0 0 0 rgba($dark, 0.6);
        background-color: $dark;
    }

    30% {
        box-shadow: 0 0 0 0 rgba($dark, 0.6);
        background-color: rgba($dark, 0.8);
    }

    50% {
        box-shadow: 0 0 0 20px rgba($dark, 0);
        background-color: $dark;
    }

    70% {
        box-shadow: 0 0 0 20px rgba($dark, 0);
        background-color: $dark;
    }

    100% {
        box-shadow: 0 0 0 0 rgba($dark, 0);
        background-color: $dark;
    }
}

@keyframes btn-spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
