@import 'variables';

html {
    box-sizing: border-box;
}

body {
    min-width: 720px;
    margin: 0;
    font-family: 'ArialRegular', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-gray;
    background-color: $mid-gray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    height: 100%;
}

input,
textarea,
select,
button {
    font-weight: 500;
    outline: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

article,
aside,
footer,
header,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
    font-family: 'ArialBold';
    font-weight: 400;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    cursor: pointer;

    &.link {
        color: $dark-gray;
        transition: color 0.2s ease;

        &:hover {
            color: $blue;
        }

        &:active {
            color: $mid-blue;
        }
    }

    &.blue-link {
        color: $blue;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.d-flex {
    display: flex;
}

.selected {
    color: #0c6ad9;
}

.capitalize-first-letter {
    text-transform: lowercase;
}

.capitalize-first-letter::first-letter {
    text-transform: uppercase;
}
