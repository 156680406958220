.mat-tooltip {
    max-width: 166px !important;
    background: rgba(38, 50, 56, 0.9);
    padding: 12px !important;
    margin: 8px !important;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;

    &__bright-without-opacity {
        background-color: #4c5263;
        border-radius: 5px !important;
        padding: 8px 12px !important;
    }
}
