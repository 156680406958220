@import 'variables';

.global-onboarding {
    &__elevate {
        position: relative;
        z-index: 1001;

        td {
            z-index: 1002;
            border: none !important;
            background: #fff;
        }

        &.new {
            &:first-child {
                &:after {
                    z-index: 1003;
                }

                &:before {
                    z-index: 1004;
                }
            }
        }
    }

    &__backdrop {
        background-color: rgba($black, 0.7);
        opacity: 0.7;
    }

    &__panel {
        z-index: auto;
    }
}

.cdk-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    min-width: 720px;
    z-index: auto !important;
}

.cdk-global-scrollblock {
    position: absolute;
    overflow: hidden !important;
}
