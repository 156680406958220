@import 'mixins';
@import 'variables';

.auth-page {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__left-column {
        display: flex;
        justify-content: center;
        width: 51%;
        background-image: url('~assets/images/login/login-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include maxW(720px) {
            display: none;
        }
    }

    &__left-column-authenticate {
        display: flex;
        justify-content: center;
        width: 51%;
        background-image: url('~assets/images/login/login-bg-authenticate.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include maxW(720px) {
            display: none;
        }
    }

    &__title {
        width: 100%;
        max-width: 329px;
        color: $blue;
        font-family: 'Arial', serif;
        font-size: 48px;
        font-weight: 700;
        line-height: 55px;
        margin: 28.6% 9.4% 0 0;
    }

    &__right-column {
        width: 49%;
        height: 100vh;
        overflow: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 16px 30px rgba(0, 0, 0, 0.15);
        background-color: #fff;

        @include maxW(720px) {
            width: 100%;
        }
    }

    &__links {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 28px 37px 28px 34px;
    }

    &__link-item {
        font-family: 'Arial', serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $blue;
    }

    &__form-wrap {
        width: 100%;
        max-width: 293px;
        margin-top: 13.75%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;

        mat-icon[svgIcon='logo'] {
            width: 80px;
            height: 32px;
            margin: 0 auto 53px auto;
        }

        .auth-page__form {
            width: 293px;

            button {
                height: 40px;
                padding: 0;
                text-transform: uppercase;

                &[disabled] {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            .form-field {
                position: relative;
                width: 100%;
                margin-bottom: 32px;
                display: flex;
                flex-direction: column;

                &.row {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 20px 0;
                }

                label {
                    display: inline-block;
                    font-family: 'Arial', serif;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: #333;
                    margin-bottom: 10px;
                }

                a {
                    font-family: 'Arial', serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: $blue;

                    &:active,
                    &:focus {
                        outline-style: none;
                        outline-width: 0 !important;
                        outline-color: unset !important;

                        &::-moz-focus-inner {
                            border: 0 !important;
                        }
                    }
                }

                span {
                    color: #999;
                    font-family: 'Arial', serif;
                    font-size: 14px;
                    line-height: 18px;
                    padding-right: 8px;
                }

                mat-icon {
                    position: absolute;
                    top: 35px;
                    right: 10px;
                    height: 20px;
                    width: 25px;
                }

                .mat-error {
                    position: absolute;
                    color: $red;
                    font-family: Arial, serif;
                    font-size: 12px;
                    line-height: 16px;
                    bottom: -17px;
                    left: 11px;
                }

                .button {
                    cursor: pointer;
                }

                input {
                    background: #fff;
                    border: 1px solid $mid-gray;
                    box-sizing: border-box;
                    border-radius: 4px;
                    height: 40px;
                    padding: 0 10px;

                    &:focus {
                        border-color: $light-blue;
                    }

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        box-shadow: 0 0 0 50px #fff inset;
                        -webkit-box-shadow: 0 0 0 50px #fff inset;
                    }

                    &::placeholder {
                        font-family: 'Arial', serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #999;
                        width: 100%;
                    }

                    &.error {
                        border-color: $red;
                    }
                }
            }
        }
    }

    &__form-title {
        width: 100%;
        margin-bottom: 53px;

        h2 {
            font-family: 'Arial', serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: $blue;
        }
    }

    &__sso-auth {
        display: flex;
        flex-direction: column;
        gap: 38px;
        align-items: center;
    }
}

.info-form {
    &__link {
        width: 100%;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: margin-top 0.2s;

        &.with-margin {
            margin-top: 20%;
        }

        .mat-icon {
            width: 40px;
            height: 40px;
            margin-bottom: 6px;
        }

        h2 {
            font-family: 'Arial', serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: $blue;
            margin-bottom: 43px;
            width: 100%;
            text-align: center;

            &.error {
                color: $red;
            }
        }

        h3 {
            font-family: 'Arial', serif;
            font-size: 14px;
            line-height: 18px;
            color: #333;
            margin-bottom: 54px;
        }

        button {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            background-color: $blue;
            border: none;
            font-family: Arial, serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            cursor: pointer;
            margin-top: -11px;
            text-transform: uppercase;

            &.error {
                background-color: $red;
                margin-top: 0;
            }
        }
    }
}
