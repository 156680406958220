@import 'variables';

.mat-progress-bar-fill::after {
    background-color: $blue;

    .green & {
        background-color: $dark-green;
    }

    .yellow & {
        background-color: $dark-yellow;
    }

    .notice-yellow & {
        background-color: $notice-yellow;
    }
}

.mat-progress-bar-buffer {
    background: #fff;

    .yellow & {
        background-color: $yellow;
    }

    .blue & {
        background-color: $light-blue;
    }
}
