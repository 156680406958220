.mat-menu-panel {
    min-height: 32px !important;
    box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);

    .mat-menu-content:not(:empty) {
        padding: 0;
    }

    .mat-menu-item {
        font-family: 'ArialRegular';
        height: 32px;
        line-height: 32px;
        color: $dark-gray;
        transition: all 0.2s ease;

        &:hover {
            color: $black;
        }
    }
}

.profile-menu {
    padding: 7px 6px;
    margin: 17px -10px 0 0;

    &.mat-menu-panel {
        min-width: 180px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);
        overflow: hidden;
    }

    .mat-menu-item {
        font-size: 12px;
        line-height: 21px;
        color: #96969e;
    }

    @media (max-width: 992px) {
        right: 55px;
    }

    @media (max-width: 768px) {
        right: 22px;

        &__arrow {
            display: none;
        }
    }
}
