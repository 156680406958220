@mixin maxW($maxw) {
    @media (max-width: $maxw) {
        @content;
    }
}

@mixin maxH($maxh) {
    @media (max-height: $maxh) {
        @content;
    }
}

@mixin minW($minw) {
    @media (min-width: $minw) {
        @content;
    }
}

@mixin minH($minh) {
    @media (min-height: $minh) {
        @content;
    }
}

@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin multi-line-ellipsis($rows) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $rows;
    line-clamp: $rows;
    -webkit-box-orient: vertical;
}

@mixin nth-children($points...) {
    @each $point in $points {
        &:nth-child(#{$point}) {
            @content;
        }
    }
}
