.alert {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.12px;
}

.alert-error {
    background-color: #da757f;

    &::before {
        content: '';
        width: 24px;
        height: 14px;
        display: block;
        margin-right: 12px;
        background: url('~assets/images/login/icon_alert_white.png') no-repeat
            top;
        background-size: 15px 14px;
    }
}
