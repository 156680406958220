.mat-snack-bar-container {
    background: rgba(#263238, 0.9);
    color: #fff;
    box-shadow: 0 4px 8px rgba(84, 110, 122, 0.24);

    &.files-uploaded-snackbar,
    &.info-snackbar {
        position: absolute;
        margin: 0;
        bottom: 130px;
        right: 0;
        left: 50%;
        transform: translateX(-50%) !important;
    }

    &.min-content {
        width: min-content;
    }
}
