@import 'variables';

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 24px;

    .form-icon {
        position: absolute;
        top: 20px;
        left: 24px;
        display: block;
        width: 16px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 0.2;
        transition: opacity 0.2s ease;
    }

    &.has-error {
        .form-control {
            box-shadow: inset 0 0px 0px 1px $red;
            border-color: $red;
        }

        &::after {
            content: '';
            position: absolute;
            top: 22px;
            right: 24px;
            display: block;
            width: 15px;
            height: 15px;
            background: url('~assets/images/login/icon_alert.png') no-repeat
                center;
            background-size: contain;
        }

        .form-icon {
            opacity: 1;
        }

        .error-message {
            display: block;
        }
    }

    .error-message {
        display: none;
        color: $red;
        font-family: 'ArialBold';
        font-size: 10px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.1px;
    }
}

.form-control {
    width: 100%;
    padding: 20px 50px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    line-height: 16px;
    letter-spacing: 0.14px;
    transition: all 0.2s ease;

    &:-moz-placeholder {
        color: $gray;
    }

    &::-moz-placeholder {
        color: $gray;
    }

    &:-ms-input-placeholder {
        color: $gray;
    }

    &::-webkit-input-placeholder {
        color: $gray;
    }

    &:hover {
        border-color: rgba(0, 0, 0, 0.5);
    }

    &:focus {
        box-shadow: inset 0 0px 0px 1px #6f90d4;
        border-color: #6f90d4;

        & ~ .form-icon {
            opacity: 1;
        }
    }
}

.form-options {
    a {
        line-height: 22px;
        color: #999;
        font-size: 12px;

        &:hover {
            color: $blue;
        }
    }
}
