@import '~assets/sass/variables';

.mat-progress-spinner,
.mat-spinner {
    circle {
        stroke: $blue;
    }
}

.page-spinner {
    &__loading {
        margin: auto;
    }

    &__loading-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        height: 100vh;
    }
}
