@import '~assets/sass/variables';
@import '~assets/sass/mixins';

.error-alert {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 24px;
    background-color: $red;
    border-radius: 5px;

    &__wrap {
        position: relative;
        height: 45px;
    }

    &--single {
        &:hover {
            .alert-animated-icon {
                opacity: 0;
                visibility: hidden;
            }

            .download-icon {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__text {
        margin-left: 10px;
        font-size: 12px;
        color: #fff;

        @include truncate(92%);
    }

    &__close-btn {
        margin-left: auto;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    &__multiple {
        position: relative;
        height: 45px;
    }

    &__multiple-panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 101;
        margin-bottom: 15px;
    }

    &__remove-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    &__icon {
        position: relative;
        height: 24px;
        width: 24px;

        .alert-animated-icon,
        .download-icon {
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.2s ease;
        }
    }
}

.mat-expansion-panel {
    background-color: $red;
    color: #fff;
}

.mat-expansion-panel-header-description {
    transition: all 0.2s ease;

    &:hover {
        background-color: #a84b54;

        .alert-animated-icon {
            opacity: 0;
            visibility: hidden;
        }

        .download-icon {
            opacity: 1;
            visibility: visible;
        }
    }
}

.alert-animated-icon {
    opacity: 1;
    visibility: visible;
}

.download-icon {
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease;

    &:hover {
        background-color: rgba($gray, 0.4);
        box-shadow: 0 0 0 5px rgba($gray, 0.4);
    }
}
